




























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PublicVoucherController'
import { Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { EnumVoucherPurpose } from '@/app/infrastructures/misc/Constants/publicVoucher'
import { PublicVoucher } from '@/domain/entities/PublicVoucher'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import { VOUCHER_PURPOSES_EDIT } from '@/app/infrastructures/misc/Constants/publicVoucher'
import IconEye from '@/app/ui/assets/eye_filled.vue';

@Component({
  components: {
    IconEye,
    ModalAction,
    Badge,
    Button,
    Modal,
    EditIcon,
    LoadingOverlay,
  },
})
export default class ProgramDetail extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  confirmationModal = false
  successModal = false

  created(): void {
    controller.getPublicVoucherDetail(this.$route.params.id)
  }

  get voucherDetail(): PublicVoucher {
    return controller.publicVoucherDetail
  }

  get statusBadgeType(): string {
    if (
      this.voucherDetail?.status?.toLowerCase() === 'expired' ||
      this.voucherDetail?.status?.toLowerCase() === 'cancelled'
    ) {
      return 'error'
    } else if (
      this.voucherDetail?.status?.toLowerCase() === 'active' ||
      this.voucherDetail?.status?.toLowerCase() === 'published'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get getVoucherPurpose(): string {
    return VOUCHER_PURPOSES_EDIT.find(el => el.value === this.voucherDetail.purpose)?.label || '-'
  }

  get voucherAmount(): string {
    return `Rp${Utils.currencyDigit(
      this.voucherDetail?.voucherData?.pointAmount || 0
    )}`
  }

  get titleDate(): string {
    return this.isDiscountByPercentage ? 'Redeem' : ''
  }

  get expiredVoucher(): string {
    return this.isDiscountByPercentage ? this.formatDate(this.voucherDetail.voucherExpiredAt) : '-'
  }

  get isParcelPointPurpose(): boolean {
    return (
      this.voucherDetail.purpose?.toUpperCase() ===
      EnumVoucherPurpose.PARCEL_POINT
    )
  }

  get isDiscountByPercentage(): boolean {
    return (
      this.voucherDetail.purpose?.toUpperCase() ===
      EnumVoucherPurpose.DISCOUNT_PERCENTAGE
    )
  }
  get getVoucherItemStatus(): string {
    return Utils.toCapitalize(this.voucherDetail.status || '-')
  }

  private cancelVoucher(): void {
    controller.deletePublicVoucher({ publicVoucherId: this.$route.params.id })
    this.confirmationModal = false
  }

  @Watch('controller.isDeleteSuccess')
  onWatchDeleteVoucher(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  private onCloseSuccessModal(): void {
    controller.getPublicVoucherDetail(this.$route.params.id)
    this.successModal = false
  }

  private formatDate(date: string | undefined, format = 'DD MMMM YYYY, HH:mm:ss Z'): string {
    return Utils.formatTimeZone(Utils.formatDate(date || '', format))
  }

  get isPendingOrActive(): boolean {
    return (
      this.voucherDetail.status === 'ACTIVE' ||
      this.voucherDetail.status === 'PENDING'
    )
  }

  beforeDestroy(): void {
    controller.setDeleteSuccess(false)
  }
}
